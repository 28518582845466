body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-image:url("/public/imgs/chinese_trainer_main_background.avif");
    background-size:cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

.font_Segoe_UI_Symbol { font-family:'Segoe UI Symbol'; } 

.search_results_add_comment_p { padding:0;margin:0;float:right;line-height:1.2em;color:white;background-color:dimgrey;width:20px;height:20px;border-radius: 30px; }
.search_result_container { border-radius:6px; border:1px solid #DDD;  }
.search_result_odd_row { background-color: #efefeff5 }
.search_result_even_row { background-color: #efefefc7 }

.comments_editor_100_btn { width:100%; }
.error-p { background-color:#ff0033;color:white;border-radius:5px;padding:4px; }
.symbol_btn { font-family:'Segoe UI Symbol'; font-size:32px;padding:0;width:80px;height:60px; }
.symbol_btn_placeholder { width:80px;height:60px;background-color:transparent; } 
.symbol_btn_green { background-color: #21ca6c; border-color:#21ca6c}
.symbol_btn_red { background-color: #fc0d1b; border-color:#fc0d1b}


.choose_sorted_word_pack_column_title_p { background-color:white;border-radius:8px;color:#333;  }

.word_pack_square_btn_outer_container { display:flex;flex-direction:column;justify-content:center;align-items:center;
                                        color:white;font-size:12px;
                                        height:120px;width:100%;border-radius:10px;
                                        background:rgba(110, 117, 124, 0.8);
                                        background: linear-gradient(45deg, rgba(110,117,124,0.75) 0%, rgba(110,117,124,0.95) 100%);
                                        border:rgba(110, 117, 124, 1.0) solid 1px; }
                                        
.word_pack_square_btn_downlaod_btn_div { width:20px;text-align:center;background-color: gray;border-radius:4px;border:white solid 1px;}
.word_pack_square_btn_delete_btn_div { width:20px;text-align:center;background-color: gray;border-radius:4px;border:white solid 1px; } 
.word_pack_square_btn_title_p { font-size:16px;font-weight:bold; }
.word_pack_square_btn_number_p { font-size:25px;font-weight:bold; }
.word_pack_square_btn_container_wdh_required { font-weight:bold;color:red; }
.word_pack_square_btn_container_ok { color:lime;font-weight:bold;-webkit-text-stroke: green;-webkit-text-stroke-width: 0.018em; } 
.word_pack_square_btn_container_neutral { color:lightgrey; } 
.word_pack_square_btn_container_upcoming { font-weight:bold;color:#fcff00; }
.word_pack_square_btn_container_recommended { font-weight:bold;color:#ffb400;-webkit-text-stroke: darkorange;-webkit-text-stroke-width: 0.018em; }
.word_pack_square_btn_container_required { font-weight:bold;color:red; }


.choose_word_pack_tiny_p { font-size:10px; }
.choose_word_pack_btn_width { width:41%; }
.choose_word_pack_btn_width_listening { width:10%}
.choose_word_pack_btn_width_downloading { width:8% }
.choose_word_pack_download_btn {  font-size:24px;margin:0;padding:0; }
.choose_word_pack_ok { color:lime;font-weight:bold;-webkit-text-stroke: green;-webkit-text-stroke-width: 0.018em; } 
.choose_word_pack_neutral { font-weight:900;-webkit-text-stroke: white;-webkit-text-stroke-width: 0.06em; } 
.choose_word_pack_upcoming { font-weight:bold;color:#fcff00;-webkit-text-stroke: darkgoldenrod;-webkit-text-stroke-width: 0.04em; }
.choose_word_pack_recommended { font-weight:bold;color:#ffb400;-webkit-text-stroke: darkorange;-webkit-text-stroke-width: 0.018em; }
.choose_word_pack_required { font-weight:bold;color:red; }

.struggle_buttons_btn_container_div { display:flex;align-items:center;justify-content:center;height:44px;width:44px; text-align:center; }
.struggle_buttons_green_btn { background-color: limegreen;border-color:greenyellow; }

.unified_training_spacing_div { height:40px; } 
.unified_training_wrong_past_2_days_div { border:rgba(255, 217, 0, 0.567) solid 1px;border-radius: 6px;width:96%;background-color:gold }
.unified_training_text_container { border:rgba(255, 255, 255, 0.9) solid 1px;border-radius: 6px;width:96%;background-color:rgba(255, 255, 255, 0.8) }
.unified_training_no_bottom_border { border-radius: 6px 6px 0px 0px;border-bottom:0; }
.unified_training_no_top_border { border-radius: 0 0 6px 6px;border-top:0; }

.waiting_animation_div { color:#333;background:rgba(255, 255, 255, 0.85);border-radius: 16px;box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.4); }

.network_status_online { text-align:center;padding:5px 0;width:100%;color:white;background-color:#00b150; }
.network_status_offline { text-align:center;padding:5px 0;width:100%;color:white;background-color:#c00000; }

.sync_manager_unscynced_elements { text-align:center;padding:5px 0;width:100%;color:white;background-color:rgba(255, 140, 0, 0.829);border:darkorange 1px solid;border-radius: 8px; } 

.modal_dark_background { background-color: rgba(0, 0, 0, 0.2);width: 100vw;height: 100vh;z-index: 0;top: 50%;left: 50%;transform: translate(-50%, -50%);position: absolute; }
.modal_centered { width:90%;position: fixed;top: 25%;left: 50%;transform: translate(-50%, -50%); }
.modal_div { background:rgba(255, 255, 255, 0.85);z-index: 10;border-radius: 16px;box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.4); } 